import React from "react";
import rehypeReact from "rehype-react";
import styled from "styled-components";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import dateformat from "dateformat";

import Head from "../../components/Head";
import Content from "../../components/Content";
import IO from "../../components/pose/IO";
import { UpPose } from "../../components/pose/Poses";
import MEDIA from "../../common/styles/media";
import color from "../../common/styles/color";

const renderAst = new rehypeReact({
  createElement: React.createElement
}).Compiler;

const Legal = ({
  data: {
    markdownRemark: { frontmatter, htmlAst, fields }
  }
}) => {
  return (
    <>
      <div className="Background">

        <Head
          pageTitle={frontmatter.title}
          pageDescription={frontmatter.description}
        />
        <IO>
          {({ isVisible, hasBeenVisible }) => (
            <UpPose pose={isVisible || hasBeenVisible ? "visible" : "hidden"}>
              <Wrapper>
                <Document>
                  <Title>{frontmatter.title}</Title>
                  <Links>
                    <Link to="/terms" activeClassName="active">
                      Terms of Service
                  </Link>
                    <Link to="/privacy-policy" activeClassName="active">
                      Privacy Policy
                  </Link>
                    <Link to="/GDPR" activeClassName="active">
                      GDPR
                  </Link>
                    <Link to="/honour-code" activeClassName="active">
                      Honour Code
                  </Link>
                    <Link to="/community-guidelines" activeClassName="active">
                      Community Guidelines
                  </Link>
                    <Link to="/cookies-policy" activeClassName="active">
                      Cookie Policy
                  </Link>
                    <Link to="/copyright" activeClassName="active">
                      Copyright Policy
                  </Link>
                  </Links>

                  <ContentWrapper>
                    <Content>{renderAst(htmlAst)}</Content>
                  </ContentWrapper>
                  <hr id="contact" />
                  <Date>
                    Last updated:&nbsp;
                  {dateformat(fields.dataModified, "dS mmmm yyyy")}
                  </Date>
                  <Text>
                    <p>
                      Have any questions? Send us an email at&nbsp;
                    <a href="mailto:info@edeogo.com">
                        info@edeogo.com
                    </a>

                    </p>

                  </Text>
                </Document>
              </Wrapper>
            </UpPose>
          )}
        </IO>
      </div>

    </>
  );
};

Legal.propTypes = {
  data: PropTypes.object.isRequired
};

export default Legal;

const ContentWrapper = styled.div`
  margin: 0 15px;
`;

const Wrapper = styled.div`
  //margin: 8vw 14px 10vw 14px;
  //margin: 8vw 14px 10vw 14px;
  padding: 50px 0;
  //background: #fff;
  display: flex;
  justify-content: center;
  align-content: center;
  margin: 0 auto;
`;

const Document = styled.div`
  text-align: left;
  background: #fff;
  max-width: 900px;
  border-radius: 20px;
  padding: 50px;
  hr {
    border: 1px solid ${color.greyLight};
    margin: 40px 0;
  }
`;

const Title = styled.h1`
  margin: 14px 14px 4px 11px;
  text-align: left;
  font-size: 40px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: -1.6px;
  ${MEDIA.DESKTOP`
    font-size: 30px;
    letter-spacing: -1.1px;
  `};
  ${MEDIA.MOBILE`
    font-size: 25px;
    letter-spacing: -1px;
  `};
`;

const Links = styled.h3`
  color: ${color.grey};
  font-size: 14px;
  line-height: 1.8;
  margin: 0px 14px 18px 14px;
  a {
    padding-right: 8px;
    text-decoration: underline;
    &:hover {
      opacity: 0.8;
    }
  }
  a.active {
    display: none;
  }
  ${MEDIA.MOBILE`
    font-size: 12px;
    a {
      display:;
    }
  `};
`;

const Date = styled.h3`
  color: ${color.grey};
  margin: 0px 14px 18px 0px;
  font-size: 14px;
`;

const Text = styled.div`
  font-size: 16px;
  line-height: 1.6;
  ${MEDIA.MOBILE`
    font-size: 14px;
  `};
  h2 {
    margin: 40px 14px 0px 14px;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    ${MEDIA.MOBILE`
      font-size: 12px;
    `};
  }
  p,
  ul,
  ol,
  li, {
    margin: 14px 14px 18px 14px;
    letter-spacing: -0.2px;
  }
  a {
    text-decoration: underline;
  }
  quote {
    background: ${color.greyLight};
    margin: 30px 0px;
    border-radius: 4px;
    padding: 14px 0vw 14px 0vw;
    h2 {
      margin-top: 0px;
      color: ${color.grey};
    }
    p {
      margin-top: 6px;
      margin-bottom: 0px;
    }
  }
`;

/*<p>
                    {/*&nbsp;or write us at:
EDEO FUTURES LTD
  < br />
  <br />
London
  < br />
  United Kingdom
                  </p >*/